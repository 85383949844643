'use client';

import {useSiteLinkPrefix} from '@/hooks/useSiteLinkPrefix';
import * as React from 'react';
import { MenuItemProps } from '@mui/material';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import { mergeSx } from '@jucy-ui/appearance';

export interface MenuItemLinkProps extends MenuItemProps {
    href: string;
    target?: string;
}
export const MenuItemLink = ({ sx, href, children, target, ...props }: MenuItemLinkProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();

    return (
        <MenuItem sx={mergeSx({ p: 0 }, sx)} {...props}>
            <Link href={addSiteLinkPrefix(href)} color="inherit" underline="none" target={target} sx={{ display: 'flex', px: 2, py: 0.5, flex: 1 }}>
                {children}
            </Link>
        </MenuItem>
    );

}
