'use client';

import {useSiteLinkPrefix} from '@/hooks/useSiteLinkPrefix';
import { safePolygon, useFloating, useHover, useInteractions } from '@floating-ui/react';
import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import NextLink from 'next/link';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import MainMenuChildMenu from '../MainMenuChildMenu';

type LargeMenuItemProps = {
    item: CmsMenuItem;
    setActive: (item: CmsMenuItem) => void;
    active?: boolean;
    container?: HTMLElement;
};
export const LargeMenuItem = ({ item, setActive, active, container }: LargeMenuItemProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const addSiteLinkPrefix = useSiteLinkPrefix();
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
    });

    const hover = useHover(context, {
        handleClose: safePolygon({ requireIntent: false, blockPointerEvents: true }),
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);
    return (
        <>
            <Tab
                sx={{
                    color: 'grey.900',
                    opacity: 1,
                    fontWeight: 300,
                    fontSize: 14,
                    px: {
                        lg: 3,
                        xs: '6px',
                    },
                    '&:hover': {
                        color: 'grey.900',
                    },
                }}
                {...(item.children?.length
                    ? {
                          ref: refs.setReference,
                          ...getReferenceProps(),
                      }
                    : undefined)}
                component={NextLink}
                onClick={() => {
                    if (item.openInNewWindow) {
                        return;
                    }
                    setActive(item);
                }}
                label={item.title}
                href={item.href ? addSiteLinkPrefix(item.href): '#'}
                target={item.openInNewWindow ? '_blank' : undefined}
                aria-current={active && 'page'}
            />
            {item.title?.length ? (
                <HoverMenu
                    {...getFloatingProps()}
                    open={isOpen}
                    ref={refs.setFloating}
                    anchorEl={container}
                    keepMounted={false}
                    hideBackdrop={true}
                    container={container}
                    anchorReference="anchorPosition"
                    anchorPosition={{
                        top: 0,
                        left: 0,
                    }}
                    disableScrollLock={true}
                    sx={{
                        pointerEvents: 'all',
                        position: 'absolute',
                        width: '100%',
                        top: 'auto',
                    }}
                    MenuListProps={{ sx: { p: 0 } }}
                    slotProps={{
                        paper: {
                            sx: {
                                p: 0,
                                marginTop: -2,
                                marginLeft: -2,
                                width: '100%',
                                pointerEvents: 'all',
                                maxHeight: 'unset',
                                maxWidth: 'unset',
                            },
                        },
                    }}
                >
                    <MainMenuChildMenu item={item} vertical={true} />
                </HoverMenu>
            ) : null}
        </>
    );
};
