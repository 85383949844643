'use client';

import { useCallback } from 'react';
import { usePathname } from 'next/navigation';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';


export const useIsActiveLink = () => {
    const pathname = usePathname();
    const addSiteLinkPrefix = useSiteLinkPrefix();
    return useCallback(
        (href?: string | null) => {
            if (!href) {
                return !pathname;
            }

            return compareLink(pathname, href) || compareLink(pathname, addSiteLinkPrefix(href));
        },
        [addSiteLinkPrefix, pathname]
    );
};
export const compareLink = (pathname?: string | null, href?: string | null,partial=false) => {
    if (!pathname || !href) {
        return false;
    }
    const pathnameClean = pathname.replace(/\/$/, '');
    const hrefClean = href.replace(/\/$/, '');
    return pathnameClean === hrefClean || (partial && pathnameClean.startsWith(hrefClean));
};
