'use client';

import * as React from 'react';
import { ButtonProps, buttonClasses } from '@mui/material';
import Button from '@mui/material/Button';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { mergeSx } from '@jucy-ui/appearance';

export type PlainButtonProps<RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent'], AdditionalProps = Record<string, unknown>> = Omit<
    ButtonProps<RootComponent, AdditionalProps>,
    'variant'
> & {
    target?: string;
};

export const PlainButton = <RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent'], AdditionalProps = Record<string, unknown>>({
    children,
    sx,
    ...props
}: PlainButtonProps<RootComponent, AdditionalProps>) => (
    <Button
        variant="text"
        sx={mergeSx(
            {
                color: 'inherit',
                px: 0,
                py: 1,
                minWidth: 0,
                ['&:hover']: { background: 'none' },
                [`& .${buttonClasses.endIcon}`]: { ml: 0 },
            },
            sx
        )}
        disableRipple={true}
        {...props}
    >
        {children}
    </Button>
);
