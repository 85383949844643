import {env} from '@/env';
import {jucyAttribution} from '@/lib/jucyAttribution';
import {SearchFormValues} from '@jucy-ui/components/SearchForm';
import {format} from 'date-fns';

const buildObeSearchParams = (values: Partial<SearchFormValues>): URLSearchParams => {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(values)) {
        let result = value;
        if (result) {
            if (['pickUpDate', 'dropOffDate'].includes(key)) {
                result = format(result as Date, 'yyyy-MM-dd');
            }
            if (['pickUpTime', 'dropOffTime'].includes(key)) {
                result = format(result as Date, 'HHmm');
            }
            if (key === 'dropOffBranch') {
                searchParams.append('dropOffLocation', result as string);
                continue;
            }
            if (key === 'pickUpBranch') {
                searchParams.append('pickUpLocation', result as string);
                continue;
            }
            searchParams.append(key, result as string);
        }
    }
    for (const [param, value] of [...jucyAttribution.getForwardUrlSearchParams()]) {
        if (value) {
            searchParams.set(param, value);
        }
    }
    return searchParams;
};

export const buildObeSearchUrl = (values: SearchFormValues, region = 'nz'): URL => {
    const { fleetType, ...rest } = values;
    const url = new URL(`/obe/${region}/direct/create-quote/${fleetType}`, `${env.NEXT_PUBLIC_OBE_DIRECT_URL}`);
    for (const [key, value] of buildObeSearchParams(rest).entries()) {
        url.searchParams.set(key, value);
    }
    url.searchParams.append('action', 'search');
    return url;
};

export const buildObeLinkUrl =({path,params}:{path:string,params?:Record<string,string>}): URL => {
    const url = new URL(path, `${env.NEXT_PUBLIC_OBE_DIRECT_URL}`);
    for (const [key, value] of buildObeSearchParams(params||{}).entries()) {
        url.searchParams.set(key, value);
    }
    return url
}
