'use client';

import React from 'react';
import { tabsClasses } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { usePathname } from 'next/navigation';
import { compareLink } from '@/hooks/useIsActiveLink';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import { LargeMenuItem } from './LargeMenuItem';

const useActiveTabItem = (items: CmsMenuItem[]) => {
    const pathname = usePathname();
    const activeItem = pathname && items.find((item) => compareLink(pathname, item.href));
    const activeIndex = activeItem && items.findIndex((item) => item.id === activeItem.id);
    return {
        activeItem: activeItem || null,
        activeIndex: typeof activeIndex === 'number' ? activeIndex : false,
    };
};

export interface LargeMenuProps {
    container?: HTMLElement;
    items: CmsMenuItem[];
}

export const LargeMenu = ({ container, items }: LargeMenuProps) => {
    const { activeItem, activeIndex } = useActiveTabItem(items || []);
    const [currentTab, setCurrentTab] = React.useState<number | boolean>(activeIndex);
    return (
        <Tabs
            sx={(theme) => ({
                display: { xs: 'none', sm: 'flex' },
                height: theme.settings.appBar.heights,
                [`& .${tabsClasses.flexContainer}`]: { height: '100%' },
                [`& .${tabsClasses.indicator}`]: { height: 4, pointerEvents: 'none' },
            })}
            value={currentTab}
        >
            {items.map((item) => (
                <LargeMenuItem
                    key={item.id}
                    item={item}
                    container={container}
                    setActive={(active) => {
                        const activeIndex = activeItem && items.findIndex((item) => item.id === active.id);
                        setCurrentTab(typeof activeIndex === 'number' ? activeIndex : false);
                    }}
                />
            ))}
        </Tabs>
    );
};
