'use client';

import * as React from 'react';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { unstable_useId as useId } from '@mui/material/utils';
import { useLocale } from '@jucy-ui/components';
import { MenuButton } from '@/components/MenuButton';
import { useSite } from '@/hooks/useSite';
import { sites } from '@/lib/Sites';


export interface RegionMenuProps {
    id?: string;
}

export const RegionMenu = ({ id }: RegionMenuProps) => {
    const elId = useId(id || 'region-select');
    const currentSite = useSite();
    const locale = useLocale();
    return (
        <MenuButton id={elId} label={currentSite.title}>
            <ListItem>Select a region</ListItem>
            {sites.map((site) => (                <MenuItem key={site.site} sx={{p: 0,...(site.site === currentSite.urlSegment ? {fontWeight: 'bold'} : undefined)}}>
                    <Link href={`/${site.site}/${locale.split('_')[0]}`} color="inherit" underline="none" sx={{display: 'flex', px: 2, py: 0.5, flex: 1}}>
                        {site.name}
                    </Link>
                </MenuItem>))}
        </MenuButton>
    );
};
