'use client';
import * as React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { AccordionDetailsProps as MuiAccordionDetailsProps } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const JucyMuiAccordionDetails: React.FC<MuiAccordionDetailsProps> = ({ sx, ...props }) => (
    <MuiAccordionDetails
        sx={mergeSx(
            (theme) => ({
                padding: {
                    xs: theme.spacing(1, 0, 2, 1),
                    sm: theme.spacing(1, 0, 2, 4),
                },
            }),
            sx
        )}
        {...props}
    />
);
