'use client';

import * as React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system/styleFunctionSx';
import { mergeSx } from '@jucy-ui/appearance';
import { SiteContainer } from '@/components/SiteContainer';

export interface NavBarContainerProps {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}

export const NavBarContainer = ({ children, sx }: NavBarContainerProps) => (
    <Box
        sx={mergeSx(
            {
                bgcolor: 'secondary.main',
                typography: 'caption',
                color: 'secondary.contrastText',
            },
            sx
        )}
    >
        <SiteContainer sx={{ justifyContent: 'space-between', display: 'flex' }}>{children}</SiteContainer>
    </Box>
);
