'use client';

import * as React from 'react';
import { unstable_useId as useId } from '@mui/material/utils';
import { slugify } from '@jucy-ui/common';
import { MenuButton } from '@/components/MenuButton';
import { MenuItemLink } from '@/components/MenuItemLink';
import { useIsActiveLink } from '@/hooks/useIsActiveLink';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsMenu } from '@/services/cms/models/CmsMenu';


export interface TopBarMenuProps {
    id?: string;
    menu: CmsMenu;
}

export const TopBarMenu = ({ id, menu }: TopBarMenuProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    const elId = useId(id || slugify(`menu-${menu.slug}-${menu.id}`));
    const isActiveLink = useIsActiveLink();

    return (
        <MenuButton id={elId} label={menu.title}>
            {menu.links?.map((link) => (
                    <MenuItemLink selected={isActiveLink(link.url)}  key={link.id} href={addSiteLinkPrefix(link.url)} target={link.openInNew ? '_blank' : undefined}>
                        {link.title}
                    </MenuItemLink>
                ))}
        </MenuButton>
    );
};
