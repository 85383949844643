export type SiteCode = 'au' | 'nz';
export type Site = {
    site: SiteCode;
    name: string;
};

export const DEFAULT_SITE_CODE: SiteCode = 'nz' as const;
export const DEFAULT_SITE_LOCALE = 'en' as const;

export const sites = [
    {
        site: 'au',
        name: 'Australia',
    },
    {
        site: 'nz',
        name: 'New Zealand',
    },
] as const;

export const DEFAULT_SITE = sites.find((site) => site.site === DEFAULT_SITE_CODE) as Site;
