'use client';

import {buildObeLinkUrl} from '@/lib/buildObeSearchUrl';
import {CmsLink} from '@/services/cms/models/CmsLink';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import { JucyLogo } from '@jucy-ui/components';
import EnvRibbon from '@/components/EnvRibbon';
import { TopNavBar } from '@/components/NavBars/TopNavBar';
import { CTAs } from '@/components/SiteAppBar/CTAs';
import { SiteContainer } from '@/components/SiteContainer';
import { useGetSitePrefix } from '@/hooks/useGetSitePrefix';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { SiteAppBarMenu } from './SiteAppBarMenu';

export interface SiteAppBarProps {
    siteConfig: CmsSiteConfig;
    menuItems?: CmsMenuItem[];
}

export const SiteAppBar = ({ siteConfig, menuItems }: SiteAppBarProps) => {
    const [toolBarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);
    const [bookNowLink,setBookNowLink] = useState<CmsLink | undefined>(siteConfig?.siteConfig?.bookNowLink);

    useEffect(() => {
        setBookNowLink(resolveBookNowLink(siteConfig));
    }, [siteConfig]);

    const homeLink = useGetSitePrefix();
    return (
        <Box component="header">
            <AppBar component="div" sx={{ color: 'text.primary', borderTop: 'none' }}>
                <EnvRibbon />
                <TopNavBar siteConfig={siteConfig} />
                <SiteContainer>
                    <Toolbar sx={(theme) => ({ height: theme.settings.appBar.heights, position: 'relative' })} ref={(el) => setToolbarRef(el)} disableGutters>
                        <Link href={homeLink}>
                            <JucyLogo style={{ width: '133px', height: 'auto', marginRight: 16 }} />
                        </Link>
                        <SiteAppBarMenu siteConfig={siteConfig} container={toolBarRef || undefined} menuItems={menuItems} />
                        <CTAs sx={{ ml: 'auto', display: { xs: 'none', md: 'flex' } }} links={[
                            siteConfig.siteConfig.checkInLink, bookNowLink
                        ].filter(Boolean)} />
                    </Toolbar>
                </SiteContainer>
            </AppBar>
        </Box>
    );
};

const resolveBookNowLink = (  siteConfig: CmsSiteConfig) => {
    const bookNowLink = siteConfig?.siteConfig?.bookNowLink;
    if (!bookNowLink?.url) {
        return undefined;
    }
    try {
        const url = new URL(bookNowLink.url);
        const convertedUrl = buildObeLinkUrl({ path: url.pathname, params: Object.fromEntries(url.searchParams) });
        url.pathname = convertedUrl.pathname;
        for (const [key, value] of convertedUrl.searchParams) {
            url.searchParams.set(key, value);
        }
        return {
            ...bookNowLink,
            url: url.toString(),
        };
    }catch(e){
        return bookNowLink
    }
}
