'use client';

import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccordionSummaryProps, styled } from '@mui/material';
import accordionClasses from '@mui/material/Accordion/accordionClasses';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';

type JucyMuiAccordionVariant = 'plus' | 'chevron';
export interface JucyMuiAccordionSummaryProps extends AccordionSummaryProps {
    variant?: JucyMuiAccordionVariant;
}
export const JucyMuiAccordionSummary = styled((props: JucyMuiAccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={
            <>
                {props.variant === 'chevron' ? (
                    <ExpandMoreIcon color="primary" />
                ) : (
                    <>
                        <AddIcon fontWeight={700} className="expand" />
                        <RemoveIcon fontWeight={700} className="collapsed" />
                    </>
                )}
            </>
        }
        {...props}
    />
))<JucyMuiAccordionSummaryProps>(({ theme, variant }) => ({
    ...(variant === 'chevron'
        ? {}
        : {
              flexDirection: 'row-reverse',
              [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
                  color: theme.palette.text.primary,
                  transform: 'rotate(-180deg)',
                  '& .expand': {
                      display: 'block',
                  },
                  '& .collapsed': {
                      display: 'none',
                  },
                  [`&.${accordionClasses.expanded}`]: {
                      transform: 'rotate(0)',
                      '& .expand': {
                          display: 'none',
                      },
                      '& .collapsed': {
                          display: 'block',
                      },
                  },
              },
              [`& .${accordionSummaryClasses.content}`]: {
                  marginLeft: theme.spacing(1),
              },
              padding: 0,
          }),
}));
