import React from 'react';
import { tabClasses } from '@mui/material';
import Link from '@mui/material/Link';
import { JucyMuiAccordion, JucyMuiAccordionDetails, JucyMuiAccordionSummary } from '@jucy-ui/components/JucyMuiAccordion';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import MainMenuChildMenu from '../MainMenuChildMenu';


const mobileLinkStyle = {
    color: 'text.primary',
};
type SmallMenuItemProps = {
    item: CmsMenuItem;
    setActive: (item: CmsMenuItem | null) => void;
    active?: boolean;
};
export const SmallMenuItem = ({ item, active, setActive }: SmallMenuItemProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    if (!item.children) {
        return (
            <Link
                sx={{
                    color: 'text.primary',
                    px: 2,
                    my: 1.5,
                    '&:hover': {
                        color: 'grey.900',
                    },
                    display: 'block',
                }}
                href={item.href ? addSiteLinkPrefix(item.href) : undefined}
                target={item.openInNewWindow ? '_blank' : undefined}
            >
                {item.title}
            </Link>
        );
    }

    return (
        <JucyMuiAccordion
            expanded={active}
            onChange={() => {
                setActive(active ? null : item);
            }}
            key={item.id}
            className="mobile-menu-item"
            sx={{ position: 'static' }}
        >
            <JucyMuiAccordionSummary sx={mobileLinkStyle} variant="chevron" aria-controls={`mobile-menu-content-${item.id}`} id={`mobile-menu-header-${item.id}`}>
                {item.title}
            </JucyMuiAccordionSummary>
            <JucyMuiAccordionDetails
                aria-labelledby={`mobile-menu-header-${item.id}`}
                id={`mobile-menu-content-${item.id}`}
                sx={{
                    p: { xs: 0 },
                    [`& .${tabClasses.textColorInherit}`]: { pl: 4 },
                }}
            >
                <MainMenuChildMenu item={item} vertical={true} />
            </JucyMuiAccordionDetails>
        </JucyMuiAccordion>
    );
};
