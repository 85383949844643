import * as React from 'react';
import { MenuItemProps } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { unstable_useId as useId } from '@mui/material/utils';
import { MenuButton } from '@/components/MenuButton';
import { MenuItemLink } from '@/components/MenuItemLink';
import { CmsLink } from '@/services/cms/models/CmsLink';

export interface CallUsMenuProps {
    id?: string;
    primaryPhoneNumber?: CmsLink | null;
    phoneNumbers?: CmsLink[] | null;
}

export const CallUsMenu = ({ id, primaryPhoneNumber, phoneNumbers }: CallUsMenuProps) => {
    const elId = useId(id || 'call-us');
    return (
        <MenuButton id={elId} label="Call us">
            {primaryPhoneNumber ? <CallUsMenuItem number={primaryPhoneNumber}>International&nbsp;</CallUsMenuItem> : null}
            {phoneNumbers?.length ? <Divider /> : null}
            {phoneNumbers?.length ? <ListItem>Free phone:</ListItem> : null}
            {phoneNumbers?.length ? phoneNumbers.map((p) => <CallUsMenuItem key={p.id} number={p} prefix={true} sx={{ py: 0 }} />) : null}
        </MenuButton>
    );
};

const formatLabel = (label: string) => {
    const parts = label.split(' ');
    if (!parts.length || parts.length === 1) {
        return { title: label };
    }
    return {
        prefix: parts[0],
        title: parts.slice(1).join(' '),
    };
};

interface CallUsMenuItemProps extends Omit<MenuItemProps, 'prefix'> {
    number: CmsLink;
    prefix?: boolean;
}
const CallUsMenuItem: React.FC<CallUsMenuItemProps> = ({ children, number, prefix: inPrefix, ...props }) => {
    const { title, prefix } = inPrefix ? formatLabel(number.title || '') : { title: number.title, prefix: undefined };
    return (
        <MenuItemLink {...props} href={number.url}>
            {children}
            {prefix ? (
                <Box component="span" sx={{ width: '38px' }}>
                    {prefix}
                </Box>
            ) : null}
            {title}
        </MenuItemLink>
    );
};
