'use client';

import * as React from 'react';
import { buttonClasses } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CallUsMenu } from '@/components/Menus/CallUsMenu';
import { RegionMenu } from '@/components/Menus/RegionMenu';
import { TopBarMenu } from '@/components/Menus/TopBarMenu';
import { PlainButton } from '@/components/PlainButton';
import { CTAs } from '@/components/SiteAppBar/CTAs';
import { CmsLink } from '@/services/cms/models/CmsLink';
import { CmsMenu } from '@/services/cms/models/CmsMenu';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import { SmallMenuItem } from './SmallMenuItem';

interface SmallMenuDrawContent {
    items: CmsMenuItem[];
    ctaLinks?: CmsLink[];
    topBarMenus?: CmsMenu;
}

export const SmallMenuDrawContent: React.FC<SmallMenuDrawContent> = ({ items, ctaLinks, topBarMenus }) => {
    const [activeItem, setActiveItem] = React.useState<CmsMenuItem | null>(null);
    return (
        <>
            {ctaLinks ? <CTAs links={ctaLinks} sx={{ px: 2, justifyContent: 'center' }} /> : null}
            {items.map((item) => (
                <SmallMenuItem key={item.id} item={item} active={activeItem?.id === item.id} setActive={(item) => setActiveItem(item)} />
            ))}
            <Stack sx={{ px: 2, [`& .${buttonClasses.root}`]: { justifyContent: 'space-between' } }}>
                <CallUsMenu />
                <PlainButton href="mailto:res@jucyworld.com">Email Us</PlainButton>
                <RegionMenu />
                {topBarMenus ? <TopBarMenu menu={topBarMenus} /> : null}
            </Stack>
        </>
    );
};
