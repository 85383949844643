'use client';

import * as React from 'react';
import Stack from '@mui/material/Stack';
import { CallUsMenu } from '@/components/Menus/CallUsMenu';
import { RegionMenu } from '@/components/Menus/RegionMenu';
import { TopBarMenu } from '@/components/Menus/TopBarMenu';
import { NavBarContainer } from '@/components/NavBars/NavBarContainer';
import { NavBarDivider } from '@/components/NavBars/NavBarDivider';
import { PlainButton } from '@/components/PlainButton';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';

export interface TopNavBarProps {
    siteConfig: CmsSiteConfig;
}

export const TopNavBar = ({ siteConfig }: TopNavBarProps) => (
    <NavBarContainer
        sx={{
            display: {
                xs: 'none',
                sm: 'block',
            },
        }}
    >
        <Stack direction="row" gap={1}>
            {siteConfig.siteConfig.primaryPhoneNumber && siteConfig.siteConfig?.phoneNumbers ? (
                <>
                    <CallUsMenu primaryPhoneNumber={siteConfig.siteConfig.primaryPhoneNumber} phoneNumbers={siteConfig.siteConfig.phoneNumbers} />
                    <NavBarDivider />
                </>
            ) : null}
            <PlainButton href="mailto:res@jucyworld.com">Email Us</PlainButton>
            <NavBarDivider />
            <RegionMenu />
        </Stack>
        <Stack direction="row" gap={1}>
            {siteConfig.siteConfig.headerMenus?.map((menu) => <TopBarMenu key={menu.id} menu={menu} />)}
        </Stack>
    </NavBarContainer>
);
