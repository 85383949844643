import { ButtonProps, styled } from '@mui/material';
import Button from '@mui/material/Button';

export interface CTAButtonProps extends ButtonProps {
    target?: string;
}
export const CTAButton = styled(Button)<CTAButtonProps>(({ theme }) => ({
    fontWeight: 600,
    padding: theme.spacing(1, 2),
}));
