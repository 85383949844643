import React from 'react';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { LargeMenu } from './components/LargeMenu/LargeMenu';
import { SmallMenu } from './components/SmallMenu/SmallMenu';

export interface SiteAppBarMenuProps {
    menuItems?: CmsMenuItem[];
    container?: HTMLElement;
    siteConfig: CmsSiteConfig;
}

export const SiteAppBarMenu = ({ container, menuItems }: SiteAppBarMenuProps) => (
    <>
        <SmallMenu items={menuItems || []} /> <LargeMenu items={menuItems || []} container={container} />
    </>
);
