import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { listClasses, unstable_useId as useId } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import { useIsSmallView } from '@jucy-ui/common';
import { PlainButton } from '@/components/PlainButton';

export interface MenuButtonProps {
    id?: string;
    label: React.ReactNode;
    children: React.ReactNode;
}

export const MenuButton = ({ id: passedId, label, children }: MenuButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const id = useId(passedId);
    const btnId = `${id}-btn`;
    const menuId = `${id}-menu`;

    const isSm = useIsSmallView();
    return (
        <>
            <PlainButton
                id={btnId}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={isSm ? <OpenInNewIcon /> : <ExpandMoreIcon fontSize="small" />}
            >
                {label}
            </PlainButton>
            {isSm ? (
                <Dialog onClose={handleClose} open={open} fullScreen={true}>
                    <DialogTitle>
                        {label}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: theme.spacing(0.5),
                                top: theme.spacing(0.5),
                                color: theme.palette.primary.main,
                            })}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <MenuList sx={{ pt: 0 }} onClick={handleClose}>
                        {children}
                    </MenuList>
                </Dialog>
            ) : (
                <Menu
                    id={menuId}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    MenuListProps={{
                        'aria-labelledby': btnId,
                    }}
                    sx={{
                        [`& .${listClasses.root}`]: {
                            px: 0,
                            py: 1,
                        },
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                p: 0,
                                borderColor: 'primary.main',
                                borderWidth: 2,
                                borderStyle: 'solid',
                            },
                        },
                    }}
                >
                    {children}
                </Menu>
            )}
        </>
    );
};
