'use client';

import React from 'react';
import { ContainerProps } from '@mui/material';
import Container from '@mui/material/Container';

export type SiteContainerProps = Omit<ContainerProps, 'maxWidth'>;
export const SiteContainer = React.forwardRef<HTMLDivElement, SiteContainerProps>(({ children, ...props }, ref) => (
    <Container ref={ref} maxWidth="xl" {...props}>
        {children}
    </Container>
));
SiteContainer.displayName = 'SiteContainer';
