import React from 'react';
import Stack from '@mui/material/Stack';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';

const HorizontalTabs = React.lazy(() => import('./HorizontalTabs'));
const InfoPanel = React.lazy(() => import('./InfoPanel'));
const Link = React.lazy(() => import('./MainMenuLink'));
const LinkGrid = React.lazy(() => import('./LinkGrid'));
const VerticalMenuTabs = React.lazy(() => import('./VerticalMenuTabs'));

interface MainMenuChildMenuProps {
    item: CmsMenuItem;
    vertical?: boolean;
    isAnimating?: boolean;
}

const hasChildren = (item: CmsMenuItem): item is CmsMenuItem & { menuChildren: CmsMenuItem[] } => Boolean(item.children && item.children.length);

const MainMenuChildMenu: React.FC<MainMenuChildMenuProps> = ({ item, vertical, isAnimating }) => {
    if (!hasChildren(item)) {
        return null;
    }
    if (item.childDisplayMode === 'Tabs') {
        return vertical ? <VerticalMenuTabs item={item} /> : <HorizontalTabs item={item} isAnimating={isAnimating} />;
    } else if (item.childDisplayMode === 'Info') {
        return <InfoPanel item={item} />;
    } else if (item.childDisplayMode === '4 Columns' || item.childDisplayMode === '3 Columns') {
        return <LinkGrid item={item} />;
    } else if (item.children) {
        return (
            <Stack component="nav" direction="row" spacing={2}>
                {item.children.map((child) => (
                    <Link key={`nav-item-${child.id}`} item={child} showImage={false} />
                ))}
            </Stack>
        );
    }
    return null;
};

export default MainMenuChildMenu;
